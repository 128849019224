<style lang="scss">
@import "~@/assets/css/var";

.dialog_orr.my-dialog {
    .el-dialog {
        margin-bottom: 0 !important;
        background: url('~@/assets/img/dialog-main.png') #fff no-repeat center / 110% 95%;
        background-position: center bottom 23px;

        &__header {
            background: unset !important;
        }

        &__title {
            color: #000 !important;
        }

        &__close {
            color: #000 !important;
        }

        &__body {
            padding: 10px 20px 10px 160px !important;
        }
    }
}

.through-table {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 15px;
    right: 15px;
    // bottom: 15px;
    padding: 15px;
    width: 280px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);

    &__title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 15px;

        i {
            color: $red;
            float: right;

        }
    }

    &__foot {
        text-align: center;
    }

    .icon_left {
        width: 40px;
        position: absolute;
        text-align: center;
        left: -35px;
        top: 40%;

        .leftActive {
            transform: rotate(90deg);
        }

        .rightActive {
            transform: rotate(270deg);
        }
    }
}
</style>

<template>
    <div v-show="show" class="through-table" :style="{ 'width': leftSelect ? '280px' : '410px' }">
        <div class="icon_left through-page-assist__bottom">
            <i class="clickable el-icon-caret-bottom" :class="{ leftActive: leftSelect, rightActive: !leftSelect, }"
                @click="leftSelect = !leftSelect"></i>
        </div>
        <div class="through-table__title move">{{ title }}{{ total ? `(${total})` : '' }}<i
                class="clickable el-icon-close" @click="show = false"></i></div>
        <div>
            <el-input placeholder="请输入关键字搜索" v-model="tempKeyword" @keyup.enter.native="searchFunc">
                <el-button slot="append" icon="el-icon-search" @click="searchFunc"></el-button>
            </el-input>
        </div>

        <div class="my-table" style="padding: 0" v-loading="loading">
            <el-table :data="list" border max-height="580" @sort-change="sortChange">
                <el-table-column fixed type="index" width="40" label="#" align="center"
                    header-align="center"></el-table-column>
                <el-table-column :prop="showField" :label="showFieldLabel" header-align="center" min-width="90" fixed>
                    <template slot-scope="props">
                        <el-button v-if="showField === 'indication'" type="text" @click="goIndication(props.row)">{{
        props.row[showField] }}</el-button>
                        <el-button v-else-if="showField === 'component'" type="text" @click="goComponent(props.row)">{{
        props.row[showField] }}</el-button>
                        <el-button v-else-if="showField === 'compName'" type="text"
                            @click="goSearchComp(props.row[showField])">{{ props.row[showField] }}</el-button>
                        <el-button v-else type="text" @click="goSearchCom(props.row[showField])">{{ props.row[showField]
                            }}</el-button>
                    </template>
                </el-table-column>
                <template v-if="['comName'].includes(showField)">
                    <el-table-column v-for="(orr, i) in col" :key="i" :prop="orr.key" :label="orr.value"
                        header-align="center" width="52" sortable="custom" align="center">
                        <template slot-scope="props">
                            <el-button type="text" @click="handleClick(props.row, orr.value)"
                                v-if="props.row[orr.key]">{{ props.row[orr.key] }}</el-button>
                            <span v-else>--</span>
                        </template>
                    </el-table-column>
                </template>
                <template v-if="!['component', 'indication'].includes(showField)">
                    <el-table-column
                        v-if="['componentQty', 'manufacturersNumber', 'compNumber', 'indicationNumber'].includes(countField)"
                        :prop="countField" label="企业数量" header-align="center" width="50" sortable="custom"
                        align="center">
                        <template slot-scope="props">
                            <el-button type="text" @click="setCompField(props.row)"
                                v-if="props.row.manufacturersNumber || props.row.compNumber">{{
        props.row.manufacturersNumber || props.row.compNumber }}</el-button>
                            <span v-else>--</span>
                        </template>
                    </el-table-column>
                </template>
                <el-table-column v-if="!hideCount" :prop="countField" :label="numShow" header-align="center"
                    sortable="custom" align="center" width="50">
                    <template slot-scope="props">
                        <el-button type="text" @click="setCountField(props.row)" v-if="countField === 'componentQty'">{{
        props.row[countField] ? props.row[countField] : '--' }}</el-button>
                        <span v-else>{{ props.row[countField] ? props.row[countField] : '--' }}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="['comName', 'compName'].includes(showField)" :prop="saleAmount" sortable="custom"
                    label="销售额(万元)" header-align="center" align="center" min-width="60">
                    <el-tag v-if="!$help.token.get()" type="danger" @click="fieldAuth = true">会员查看</el-tag>
                </el-table-column>
            </el-table>
        </div>

        <div class="through-table__foot">
            <el-pagination @current-change="pageChange" :current-page.sync="pageNo" :pager-count="5"
                :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
        </div>
        <ts-dialog class="dialog_orr" :show.sync="fieldAuth" title="提示" hide-footer width="450px">
            <div class="field-auth">
                <p class="title">您还不是会员</p>
                <p class="assist">开通会员后即可查看全部数据</p>
                <div style="margin-top: 30px;">
                    <el-button plain type="primary" @click="$router.push('/members')" size="normal"
                        style="background: #fff;color: #5171DA;border-color: #5171DA;">去开通</el-button>
                    <el-button type="primary" @click="goCustom()" size="normal">联系客服</el-button>
                </div>
            </div>
        </ts-dialog>
        <customer-service ref="cs" :unSocket="false" />
    </div>
</template>

<script>
export default {
    props: {
        text: {
            typeof: String,
            default: ''
        },
        col: {
            typeof: Array,
            default: () => []
        },
    },
    data() {
        return {
            fieldAuth: false,
            show: false,
            loading: false,
            title: '',
            list: [],

            tempKeyword: '',

            parentModel: {},
            keyword: '',
            api: '',
            pageNo: 1,
            pageSize: 50,
            total: 0,

            queryField: '',
            keywordField: '',
            showField: '',
            countField: '',
            saleAmount: '',

            showFieldLabel: '',
            numShow: '',

            hideCount: false,  // 是否隐藏数量列
            sort: undefined,

            leftSelect: true,
        }
    },
    methods: {
        goCustom() {
            if (window.removeFinished) {
                this.$refs.cs.showDialog()
            }
        },
        //医保、基药穿透
        handleClick(row, key) {
            let url = ''
            if (key === '医保') {
                url = '/store/du_health_insurance_directories'
            } else if (key === '基药') {
                url = '/store/du_essential_drugs'
            }
            let obj = {
                comCode: row.comCode,
            }
            this.goPage(url, obj)
        },
        goPage(url, obj) {
            const { href } = this.$router.resolve({
                path: url,
                query: obj,
            });
            window.open(href, "_blank");
        },
        //点击成分跳转中药材
        goComponent(row) {
            console.log('点击成分跳转中药材', row);
            if (row.chineseMedicinal && row.chineseMedicinalCode) {
                let obj = {
                    comCode: row.chineseMedicinalCode,
                    comName: row.chineseMedicinal,
                }
                this.goPage('/ch-medicinal-price', obj)
            } else {
                this.$message.warning('暂无此成分基本信息！')
                return
            }

        },
        //点击疾病
        goIndication(row) {
            this.$emit('goIndication', row)
            this.unShow()
        },
        //点击成分数量
        setCountField(row) {
            this.$emit('setCountField', row)
            this.unShow()
        },
        //点击企业数量
        setCompField(row) {
            let obj = {
                comCode: row.comCode,
            }
            this.goPage('/store/dr_domestic_drugs', obj)
            // this.unShow()
        },
        unShow() {
            this.show = false
        },
        init(param) {
            console.log('111111', param);
            this.show = true
            this.title = param.parentTitle
            this.api = param.config.query
            this.queryField = param.config.queryField
            this.keywordField = param.config.keywordField
            this.countField = param.config.countField
            this.showField = param.config.showField
            this.saleAmount = param.config.saleAmount
            this.parentModel = param.parentModel
            this.hideCount = param.config.hideCount || false
            this.tempKeyword = ''
            this.showFieldLabel = {
                comName: '药品',
                compName: '企业',
                component: '成分',
                indication: '疾病',
            }[this.showField]
            this.numShow = param.config.countShow || '数量'

            this.searchFunc()
        },
        searchFunc() {
            this.keyword = this.tempKeyword
            this.pageNo = 1
            this.total = 0
            this.loading = false
            this.list = []
            this.getList()
        },
        pageChange(val) {
            this.pageNo = val
            this.getList()
        },
        getList() {
            this.loading = true
            this.$api.post(this.api, {
                page: {
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                },
                params: {
                    [this.queryField]: this.parentModel[this.queryField],
                    [this.keywordField]: this.keyword ? `%${this.keyword}%` : undefined,
                },
                sort: this.sort,
            })
                .then(res => {
                    this.total = res.data.totalCount
                    this.list = res.data.records.map(item => {
                        if (item.saleAmount === 0 || item.saleAmount === '0') {
                            item.saleAmount = ''
                        }
                        return item
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },

        sortChange(param) {
            let sort = undefined
            if (param.order) {
                sort = {
                    [param.prop]: param.order === 'ascending' ? 'asc' : 'desc'
                }
            }
            this.sort = sort
            this.getList()
        },
    }
}
</script>